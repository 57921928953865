export default {
  homepage: "Главная страница",
  blogs: "Блоги",
  blog: "Блог",
  searchByName: "поиск по имени",
  categories: "Категории",
  product: "Продукт",
  reviews: "Отзывы",
  polices: "Политики",
  companies: "Компании",
  exhibitions: "Выставки",
  requests: "Запросы",
  request: "Запрос",
  services: "Услуги",
  service: "Услуга",
  allCategories: "Все категории",
  allServices: "Все услуги",
  articles: "Статьи",
  addRequest: "Add Request",
  addProjectIdea: "Add Project Idea",
  about: "О нас",
  contact: "Контакты",
  copyRight: "Martofworld Все права защищены",
  quickAccess: "Здесь вы можете быстро получить доступ к нужным страницам",
  quickMenu: "Быстрое меню",
  noSubSerivce: "Нет подуслуг...",
  noSubCategory: "Нет подкатегорий...",
  contactUs: "Свяжитесь с нами",

  // companies
  locationHere: "Местоположение здесь",
  chatNow: "Чат сейчас",
  noProductsFound: "Продукты не найдены.",
  seeDetails: "Смотреть детали",
  segments: "Сегменты",
  sendInquiry: "Отправить запрос",
  message: "сообщение",
  send: "Отправить",
  keywords: "Ключевые слова",
  certificates: "Сертификаты",
  confirmingBanks: "Подтверждающие банки",
  brands: "Бренды",
  address: "Адрес",
  visitCompany: "Посетить компанию",
  selectCategory: "выберите категорию",
  subCategories: "Подкатегории",
  country: "Страна",
  countryName: "Название страны",
  selectCountry: "выберите страну",
  selectSubCategory: "Выберите подкатегорию",
  cities: "Города",
  city: "Город",
  logout: "Выйти",
  viewDashboard: "Просмотр панели управления",

  // contact
  yourName: "Ваше имя",
  yourNameHere: "ваше имя здесь...",
  yourMessageHere: "ваше сообщение здесь...",
  yourEmail: "Ваш Email",
  subject: "Тема",
  optional: "Необязательно",
  submit: "Отправить",
  emailAddress: "Адрес электронной почты",
  resetFilters: "Сбросить фильтры",
  noExhibitionsFound: "Выставки не найдены",
  to: "Кому",
  from: "От",
  type: "Тип",
  noRequestsFound: "Запросы не найдены",
  subjectHere: "Тема здесь...",
  companyName: "Название компании",

  // homepage
  lastCompanies: "Последние компании",
  seeAllCategories: "Смотреть все категории",
  companiesBy: "Компании по",
  searchForCompany: "Поиск компании",
  noResult: "Нет результатов",
  fetchingCompaniesWith: "Поиск компаний с",
  projectsIdeas: "Идеи проектов",
  tendersAnd: "Тендеры и",
  seeMore: "Посмотреть больше",
  requestTitle: "Поднимите свои сбережения на новый уровень!",
  purchaseRequests: "Запросы на покупку",
  saleOffers: "Предложения о продаже",
  viewMore: "Посмотреть больше",
  searchStepTitle1: "Оптимизируйте процесс от поиска до выполнения,",
  searchStepTitle2: "Всё в одном месте",
  step1Title: "Ищите компании",
  step2Title: "Найдите лучших поставщиков",
  step3Title: "Запрашивайте котировки",
  step4Title: "Заключайте сделки",
  step5Title: "Добейтесь успеха в бизнесе",

  // requests
  views: "Просмотры",
  requestDetails: "Детали запроса",
  quantity: "Количество",
  price: "Цена",
  startingAt: "Начинается с",
  endingAt: "Заканчивается в",
  tags: "Теги",
  contactInfo: "Контактная информация",

  // about page
  productsForSale: "Продукты на продажу",
  productsForSaleDesc:
    "Множество производителей и компаний, производящих миллионы продуктов",
  companiesEarnings: "Доходы компаний",
  companiesEarningsDesc: "Миллионы долларов доходов для компаний по всему миру",
  growingBuyers: "Растущее число покупателей",
  growingBuyersDesc: "Постоянно растущее число компаний по всему миру",
  aboutLastSectionTitle: "Роль Mart Of World",
  aboutLastSectionDesc:
    "Проект Mart of World представляет собой B2B торговую систему, соединяющую компании-продавцы с покупателями, чтобы расширить диапазон бизнеса и торговли между различными компаниями и клиентами. Таким образом, система представляет собой платформу для всех компаний, независимо от того, ищут ли они продукты или услуги. Основная цель — предложить платформу, которая связывает компании вместе, чтобы они могли легко договариваться и открывать новые рынки.",
  exploreMore: "Узнать больше",
  aboutSecondSectionTitle:
    "Mart of World включает в себя множество компаний, работающих в разных областях торговли, промышленности и маркетинга, которые облегчают международные B2B торговые операции между компаниями.",
  aboutMainGoal: "Основная цель",
  aboutMainGoalDesc:
    "Основная цель заключается в том, чтобы собрать компании со всего мира на одной платформе и предоставить им необходимые инструменты для развития бизнеса и выхода на новые рынки. Mart of World также создал онлайн-платформу, которая связывает производителей, компании и предпринимателей с клиентами по всему миру, что позволяет им вести бизнес без географических ограничений.",
  aboutMartofWorld: "О Mart of World",
  internationalTrading: "Международная торговля...",
  aboutMartofWorldDesc:
    "B2B торговля способствовала и быстро расширила международную торговлю. Теперь, в интернете, люди могут общаться и завершать свою работу без усталости. Это привело к быстрому росту международной торговли и сделало межфирменную торговлю одним из важнейших методов ведения бизнеса.",
  B2B: "B2B",

  // auth pages
  enterEmailToGetCode:
    "Введите свой email, чтобы получить код для сброса пароля",
  getCode: "Получить код",
  login: "Войти",
  dontHavAccount: "Don't Have Account? ",
  backTo: "Назад к",
  password: "Пароль",
  email: "Email",
  name: "Имя",
  phone: "Телефон",
  lostPassword: "Забыли пароль?",
  logIn: "Войти",
  dontHaveAccount: "Нет аккаунта?",
  registerNow: "Зарегистрироваться",
  alreadyHaveAccount: "Уже есть аккаунт?",
  resetPassword: "Сбросить пароль",
  confirmPassword: "Подтвердить пароль",
  congrats: "Поздравляем...",
  youCanLogin: "Ваш email подтвержден, вы можете войти",
  goToLogin: "Перейти к входу",
  loading: "Загрузка...",
  verifyEmail: "Подтвердите email",
  register: "Зарегистрироваться",

  // meta
  metaDescription_Homepage: "metaDescription_ГлавнаяСтраница",
  metaKeywords_Homepage: "metaKeywords_ГлавнаяСтраница",

  metaDescription_About: "metaDescription_Онас",
  metaKeywords_About: "metaKeywords_Онас",

  metaDescription_Blogs: "metaDescription_Блоги",
  metaKeywords_Blogs: "metaKeywords_Блоги",

  metaDescription_Categories: "metaDescription_Категории",
  metaKeywords_Categories: "metaKeywords_Категории",

  metaDescription_Companies: "metaDescription_Компании",
  metaKeywords_Companies: "metaKeywords_Компании",

  metaDescription_Contact: "metaDescription_Контакты",
  metaKeywords_Contact: "metaKeywords_Контакты",

  metaDescription_Exhibitations: "metaDescription_Выставки",
  metaKeywords_Exhibitations: "metaKeywords_Выставки",

  metaDescription_Packages: "metaDescription_Пакеты",
  metaKeywords_Packages: "metaKeywords_Пакеты",

  metaDescription_Requests: "metaDescription_Запросы",
  metaKeywords_Requests: "metaKeywords_Запросы",

  metaDescription_Service: "metaDescription_Услуга",
  metaKeywords_Service: "metaKeywords_Услуга",
};
