export default {
  homepage: "Ana Sayfa",
  blogs: "Bloglar",
  blog: "Blog",
  searchByName: "isme göre ara",
  categories: "Kategoriler",
  product: "Ürün",
  reviews: "İncelemeler",
  polices: "Politikalar",
  companies: "Şirketler",
  exhibitions: "Sergiler",
  requests: "Talepler",
  request: "Talep",
  services: "Hizmetler",
  service: "Hizmet",
  allCategories: "Tüm Kategoriler",
  allServices: "Tüm Hizmetler",
  articles: "Makaleler",
  addRequest: "İstek Ekle",
  addProjectIdea: "Proje Fikri Ekle",
  about: "Hakkında",
  contact: "İletişim",
  copyRight: "Martofworld Tüm Hakları Saklıdır",
  quickAccess: "İhtiyacınız olan sayfalara buradan hızlıca erişebilirsiniz",
  quickMenu: "Hızlı Menü",
  noSubSerivce: "Alt Hizmet Yok...",
  noSubCategory: "Alt Kategori Yok...",
  contactUs: "Bize Ulaşın",

  // companies
  locationHere: "Konum burada",
  chatNow: "Şimdi Sohbet Et",
  noProductsFound: "Ürün Bulunamadı.",
  seeDetails: "Detayları Görüntüle",
  segments: "Segmentler",
  sendInquiry: "Sorgu Gönder",
  message: "Mesaj",
  send: "Gönder",
  keywords: "Anahtar Kelimeler",
  certificates: "Sertifikalar",
  confirmingBanks: "Onaylayan Bankalar",
  brands: "Markalar",
  address: "Adres",
  visitCompany: "Şirketi Ziyaret Et",
  selectCategory: "kategori seç",
  subCategories: "Alt Kategoriler",
  country: "Ülke",
  countryName: "Ülke Adı",
  selectCountry: "ülke seç",
  selectSubCategory: "Alt Kategori Seç",
  cities: "Şehirler",
  city: "Şehir",
  logout: "Çıkış Yap",
  viewDashboard: "Kontrol Panelini Gör",

  // contact
  yourName: "Adınız",
  yourNameHere: "adınız buraya...",
  yourMessageHere: "mesajınız buraya...",
  yourEmail: "E-postanız",
  subject: "Konu",
  optional: "Opsiyonel",
  submit: "Gönder",
  emailAddress: "E-posta Adresi",
  resetFilters: "Filtreleri Sıfırla",
  noExhibitionsFound: "Sergi Bulunamadı",
  to: "Kime",
  from: "Kimden",
  type: "Tür",
  noRequestsFound: "Talep Bulunamadı",
  subjectHere: "Konu buraya...",
  companyName: "Şirket adı",

  // homepage
  lastCompanies: "Son Şirketler",
  seeAllCategories: "Tüm Kategorileri Görüntüle",
  companiesBy: "Şirketler",
  searchForCompany: "Şirket Ara",
  noResult: "Sonuç Yok",
  fetchingCompaniesWith: "Şirketler Alınıyor",
  projectsIdeas: "Proje Fikirleri",
  tendersAnd: "İhaleler ve",
  seeMore: "Daha Fazlasını Gör",
  requestTitle: "Tasarrufunuzu Yeni Yüksekliklere Taşıyın!",
  purchaseRequests: "Satın Alma Talepleri",
  saleOffers: "Satış Teklifleri",
  viewMore: "Daha Fazlasını Gör",
  searchStepTitle1: "Aramadan teslimata kadar siparişi kolaylaştırın,",
  searchStepTitle2: "Hepsi bir arada",
  step1Title: "Şirketleri ara",
  step2Title: "En iyi tedarikçileri bul",
  step3Title: "Teklif iste",
  step4Title: "Anlaşmalar yap",
  step5Title: "İşinizde başarıya ulaşın",

  // requests
  views: "Görüntülemeler",
  requestDetails: "Talep Detayları",
  quantity: "Miktar",
  price: "Fiyat",
  startingAt: "Başlangıç",
  endingAt: "Bitiş",
  tags: "Etiketler",
  contactInfo: "İletişim Bilgileri",

  // about page
  productsForSale: "Satılık Ürünler",
  productsForSaleDesc:
    "Milyonlarca ürün üreten üreticiler ve şirketlerin tamamı",
  companiesEarnings: "Şirket Kazançları",
  companiesEarningsDesc:
    "Dünyadaki şirketler için milyonlarca dolarlık kazançlar",
  growingBuyers: "Büyüyen Alıcılar",
  growingBuyersDesc: "Dünyanın her yerinde sürekli büyüyen şirketler",
  aboutLastSectionTitle: "Mart Of World’ün Rolü",
  aboutLastSectionDesc:
    "Mart of World projesinin fikri, satıcı şirketleri müşterilerle buluşturan B2B uluslararası ticaret sistemidir. Bu sistem, farklı şirketler ve müşteriler arasında iş ve ticaretin genişlemesini sağlar. Sistem, ister ürün ister hizmet arayan tüm şirketler için bir mağaza işlevi görmektedir. Ana hedef, şirketleri bir araya getiren ve yeni pazarlara ulaşmalarına yardımcı olan bir platform sunmaktır.",
  exploreMore: "Daha Fazlasını Keşfedin",
  aboutSecondSectionTitle:
    "Mart of World, farklı ticaret, sanayi ve pazarlama alanlarında faaliyet gösteren birçok firmadan oluşmaktadır ve şirketler arasında uluslararası B2B ticaret operasyonlarını kolaylaştırır.",
  aboutMainGoal: "Ana Hedef",
  aboutMainGoalDesc:
    "Ana hedef, dünyanın dört bir yanındaki şirketleri bir araya getirmek ve işlerini geliştirmeleri ve yeni pazarlara ulaşmaları için gerekli araçları sağlamak. Ayrıca, Mart of World, üreticileri, şirketleri ve girişimcileri dünyanın her yerindeki müşterilerle buluşturan ve coğrafi kısıtlamalar olmadan iş yapmalarını sağlayan bir çevrimiçi platform oluşturmuştur.",
  aboutMartofWorld: "Mart of World Hakkında",
  internationalTrading: "Uluslararası Ticaret ...",
  aboutMartofWorldDesc:
    "B2B ticaret, uluslararası ticaretin hızlı büyümesine katkıda bulundu. Artık çevrimiçi olarak, insanlar yorulmadan birlikte iletişim kurabilir ve işlerini tamamlayabilirler. Bu, şirketler arası ticaretin en önemli yöntemlerden biri haline gelmesine yol açtı.",
  B2B: "B2B",

  // auth pages
  enterEmailToGetCode:
    "Şifre sıfırlama kodunu almak için e-posta adresinizi girin",
  getCode: "Kod Al",
  login: "Giriş Yap",
  dontHavAccount: "Hesabınız Yok mu?",
  backTo: "Geri",
  password: "Şifre",
  email: "E-posta",
  name: "Ad",
  phone: "Telefon",
  lostPassword: "Şifrenizi mi unuttunuz?",
  logIn: "Giriş Yap",
  dontHaveAccount: "Hesabınız yok mu?",
  registerNow: "Şimdi kayıt ol",
  alreadyHaveAccount: "Zaten bir hesabınız var mı?",
  resetPassword: "Şifreyi Sıfırla",
  confirmPassword: "Şifreyi Onayla",
  congrats: "Tebrikler...",
  youCanLogin: "E-postanız doğrulandı, şimdi giriş yapabilirsiniz",
  goToLogin: "Girişe Git",
  loading: "Yükleniyor...",
  verifyEmail: "E-postayı Doğrula",
  register: "Kayıt Ol",

  // meta
  metaDescription_Homepage: "metaDescription_Homepage",
  metaKeywords_Homepage: "metaKeywords_Homepage",

  metaDescription_About: "metaDescription_About",
  metaKeywords_About: "metaKeywords_About",

  metaDescription_Blogs: "metaDescription_Blogs",
  metaKeywords_Blogs: "metaKeywords_Blogs",

  metaDescription_Categories: "metaDescription_Categories",
  metaKeywords_Categories: "metaKeywords_Categories",

  metaDescription_Companies: "metaDescription_Companies",
  metaKeywords_Companies: "metaKeywords_Companies",

  metaDescription_Contact: "metaDescription_Contact",
  metaKeywords_Contact: "metaKeywords_Contact",

  metaDescription_Exhibitations: "metaDescription_Sergiler",
  metaKeywords_Exhibitations: "metaKeywords_Sergiler",

  metaDescription_Packages: "metaDescription_Paketler",
  metaKeywords_Packages: "metaKeywords_Paketler",

  metaDescription_Requests: "metaDescription_Talepler",
  metaKeywords_Requests: "metaKeywords_Talepler",

  metaDescription_Service: "metaDescription_Hizmet",
  metaKeywords_Service: "metaKeywords_Hizmet",
};
