export default {
  homepage: "الصفحة الرئيسية",
  blogs: "المدونات",
  blog: "مدونة",
  searchByName: "البحث بالاسم",
  categories: "الفئات",
  product: "المنتج",
  reviews: "المراجعات",
  polices: "السياسات",
  companies: "الشركات",
  exhibitions: "المعارض",
  requests: "الطلبات",
  request: "طلب",
  services: "الخدمات",
  service: "خدمة",
  allCategories: "جميع الفئات",
  allServices: "جميع الخدمات",
  articles: "المقالات",
  addRequest: "اضافة طلب",
  addProjectIdea: "اضافة فكرة مشروع",
  about: "حول",
  contact: "اتصل",
  copyRight: "جميع الحقوق محفوظة لمارت وورلد",
  quickAccess: "يمكنك الوصول بسرعة إلى الصفحات التي تحتاجها هنا",
  quickMenu: "قائمة سريعة",
  noSubSerivce: "...لا توجد خدمة فرعية",
  noSubCategory: "...لا توجد فئة فرعية",
  contactUs: "اتصل بنا",

  // companies
  locationHere: "الموقع هنا",
  chatNow: "دردشة الآن",
  noProductsFound: "لم يتم العثور على منتجات.",
  seeDetails: "عرض التفاصيل",
  segments: "القطاعات",
  sendInquiry: "إرسال استفسار",
  message: "رسالة",
  send: "إرسال",
  keywords: "الكلمات الرئيسية",
  certificates: "الشهادات",
  confirmingBanks: "البنوك المعتمدة",
  brands: "العلامات التجارية",
  address: "العنوان",
  visitCompany: "زيارة الشركة",
  selectCategory: "اختر الفئة",
  subCategories: "الفئات الفرعية",
  country: "الدولة",
  countryName: "اسم الدولة",
  selectCountry: "اختر الدولة",
  selectSubCategory: "اختر الفئة الفرعية",
  cities: "المدن",
  city: "المدينة",
  logout: "تسجيل الخروج",
  viewDashboard: "عرض لوحة التحكم",

  // contact
  yourName: "اسمك",
  yourNameHere: "اكتب اسمك هنا...",
  yourMessageHere: "اكتب رسالتك هنا...",
  yourEmail: "بريدك الإلكتروني",
  subject: "الموضوع",
  optional: "اختياري",
  submit: "إرسال",
  emailAddress: "عنوان البريد الإلكتروني",
  resetFilters: "إعادة تعيين الفلاتر",
  noExhibitionsFound: "لم يتم العثور على معارض",
  to: "إلى",
  from: "من",
  type: "النوع",
  noRequestsFound: "لم يتم العثور على طلبات",
  subjectHere: "اكتب الموضوع هنا...",
  companyName: "اسم الشركة",

  // homepage
  lastCompanies: "آخر الشركات",
  seeAllCategories: "عرض جميع الفئات",
  companiesBy: "الشركات حسب",
  searchForCompany: "البحث عن شركة",
  noResult: "لا توجد نتائج",
  fetchingCompaniesWith: "جلب الشركات مع",
  projectsIdeas: "أفكار المشاريع",
  tendersAnd: "المناقصات و",
  seeMore: "عرض المزيد",
  requestTitle: "ارفع مدخراتك إلى آفاق جديدة!",
  purchaseRequests: "طلبات الشراء",
  saleOffers: "عروض البيع",
  viewMore: "عرض المزيد",
  searchStepTitle1: "مراحل الطلب من البحث إلى التنفيذ،",
  searchStepTitle2: "كل شيء في مكان واحد",
  step1Title: "ابحث عن الشركات",
  step2Title: "ابحث عن أفضل الموردين",
  step3Title: "اطلب عروض الأسعار",
  step4Title: "قم بإبرام الصفقات",
  step5Title: "حقق النجاح في عملك",

  // requests
  views: "المشاهدات",
  requestDetails: "تفاصيل الطلب",
  quantity: "الكمية",
  price: "السعر",
  startingAt: "يبدأ من",
  endingAt: "ينتهي عند",
  tags: "الوسوم",
  contactInfo: "معلومات الاتصال",

  // about page
  productsForSale: "المنتجات للبيع",
  productsForSaleDesc: "عدد كبير من المصانع والشركات التي تنتج ملايين المنتجات",
  companiesEarnings: "أرباح الشركات",
  companiesEarningsDesc: "ملايين الدولارات من أرباح الشركات حول العالم",
  growingBuyers: "المشترين المتزايدين",
  growingBuyersDesc: "نمو مستمر للشركات المنتشرة في جميع أنحاء العالم",
  aboutLastSectionTitle: "دور مارت وورلد",
  aboutLastSectionDesc:
    "فكرة مشروع مارت وورلد هي نظام تجاري B2B دولي يربط بين الشركات البائعة والعملاء لتوسيع نطاق التجارة بين الشركات المختلفة. لذلك يمثل النظام متجرًا لجميع الشركات سواء كانوا يبحثون عن منتجات أو خدمات. الهدف الرئيسي هو تقديم منصة تربط الشركات معًا حيث يمكنها الاتفاق بسهولة، والمساعدة في الوصول إلى أسواق جديدة من خلال هذه المنصة.",
  exploreMore: "اكتشف المزيد",
  aboutSecondSectionTitle:
    "مارت وورلد يتكون من العديد من الشركات العاملة في مجالات التجارة والصناعة والتسويق المختلفة، والتي تعمل على تسهيل العمليات التجارية B2B الدولية بين الشركات.",
  aboutMainGoal: "الهدف الرئيسي",
  aboutMainGoalDesc:
    "الهدف الرئيسي هو جمع الشركات من جميع أنحاء العالم على منصة واحدة وتزويدهم بالأدوات اللازمة لتطوير أعمالهم والوصول إلى أسواق جديدة. كما أن مارت وورلد قد أنشأت منصة عبر الإنترنت تربط بين الشركات المصنعة والشركات ورجال الأعمال مع العملاء من جميع أنحاء العالم، مما يسمح لهم بإجراء الأعمال بدون قيود جغرافية، وبالتالي تحتوي على مجموعة من المنتجات والخدمات، مما يسهل على الشركات الحصول على أفضل الشركاء، أفضل الصفقات وأفضل الأعمال.",
  aboutMartofWorld: "عن مارت وورلد",
  internationalTrading: "التجارة الدولية...",
  aboutMartofWorldDesc:
    "التجارة بين الشركات B2B سهلت وسرعت التجارة الدولية في السابق. الآن عبر الإنترنت، يمكن للناس التواصل وإنهاء أعمالهم بدون عناء. هذا أدى إلى نمو سريع في التجارة الدولية وجعل التجارة بين الشركات واحدة من أهم الطرق في الأعمال.",
  B2B: "B2B",

  // auth pages
  enterEmailToGetCode:
    "أدخل بريدك الإلكتروني للحصول على رمز إعادة تعيين كلمة المرور",
  getCode: "احصل على الرمز",
  login: "تسجيل الدخول",
  dontHavAccount: "ليس لديك حساب ؟",
  backTo: "العودة إلى",
  password: "كلمة المرور",
  email: "البريد الإلكتروني",
  name: "الاسم",
  phone: "الهاتف",
  lostPassword: "هل نسيت كلمة المرور؟",
  logIn: "تسجيل الدخول",
  dontHaveAccount: "لا تملك حساب؟",
  registerNow: "سجل الآن",
  alreadyHaveAccount: "هل لديك حساب بالفعل؟",
  resetPassword: "إعادة تعيين كلمة المرور",
  confirmPassword: "تأكيد كلمة المرور",
  congrats: "تهانينا...",
  youCanLogin: "تم التحقق من بريدك الإلكتروني، يمكنك الآن تسجيل الدخول",
  goToLogin: "الذهاب إلى تسجيل الدخو",
  loading: "جارٍ التحميل...",
  verifyEmail: "تحقق من البريد الإلكتروني",
  register: "تسجيل",

  // meta
  metaDescription_Homepage: "metaDescription_Homepage",
  metaKeywords_Homepage: "metaKeywords_Homepage",

  metaDescription_About: "metaDescription_About",
  metaKeywords_About: "metaKeywords_About",

  metaDescription_Blogs: "metaDescription_Blogs",
  metaKeywords_Blogs: "metaKeywords_Blogs",

  metaDescription_Categories: "metaDescription_Categories",
  metaKeywords_Categories: "metaKeywords_Categories",

  metaDescription_Companies: "metaDescription_Companies",
  metaKeywords_Companies: "metaKeywords_Companies",

  metaDescription_Contact: "metaDescription_Contact",
  metaKeywords_Contact: "metaKeywords_Contact",

  metaDescription_Exhibitations: "metaDescription_Exhibitations",
  metaKeywords_Exhibitations: "metaKeywords_Exhibitations",

  metaDescription_Packages: "metaDescription_Packages",
  metaKeywords_Packages: "metaKeywords_Packages",

  metaDescription_Requests: "metaDescription_Requests",
  metaKeywords_Requests: "metaKeywords_Requests",

  metaDescription_Service: "metaDescription_Service",
  metaKeywords_Service: "metaKeywords_Service",
};
