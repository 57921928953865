import Vue from "vue";
import VueI18n from "vue-i18n";
import AR from "./ar";
import EN from "./en";
import DE from "./de";
import ES from "./es";
import FR from "./fr";
import RU from "./ru";
import TR from "./tr";
Vue.use(VueI18n);

export default new VueI18n({
  silentFallbackWarn: true,
  lazy: true,
  locale: localStorage.getItem("language") || "en",
  fallbackLocale: "ar",
  messages: {
    en: EN,
    ar: AR,
    de: DE,
    tr: TR,
    ru: RU,
    es: ES,
    fr: FR,
  },
});
