export default {
  homepage: "Homepage",
  blogs: "Blogs",
  blog: "Blog",
  searchByName: "search by name",
  categories: "Categories",
  product: "Product",
  reviews: "Reviews",
  polices: "Polices",
  companies: "Companies",
  exhibitions: "Exhibitions",
  requests: "Requests",
  request: "Request",
  services: "Services",
  service: "Service",
  allCategories: "All Categories",
  allServices: "All Services",
  articles: "Articles",
  addRequest: "Add Request",
  addProjectIdea: "Add Project Idea",
  about: "About",
  contact: "Contact",
  copyRight: "Martofworld All Rights Reserved",
  quickAccess: "You Can Quickly Access The Pages You Need Here",
  quickMenu: "Quick Menu",
  noSubSerivce: "No Sub Serivce...",
  noSubCategory: "No Sub Category...",
  contactUs: "Contact Us",

  // companies
  locationHere: "Location here",
  chatNow: "Chat Now",
  noProductsFound: "No Products Found.",
  seeDetails: "See Details",
  segments: "Segments",
  sendInquiry: "Send Inquiry",
  message: "message",
  send: "Send",
  keywords: "Keywords",
  certificates: "Certificates",
  confirmingBanks: "Confirming Banks",
  brands: "Brands",
  address: "Address",
  visitCompany: "Visit Company",
  selectCategory: "select category",
  subCategories: "Sub Categories",
  country: "Country",
  countryName: "Country Name",
  selectCountry: "select country",
  selectSubCategory: "Select Sub Category",
  cities: "Cities",
  city: "City",
  logout: "Logout",
  viewDashboard: "View Dashboard",

  // contact
  yourName: "Your Name",
  yourNameHere: "your name here...",
  yourMessageHere: "your message here...",
  yourEmail: "Your Email",
  subject: "Subject",
  optional: "Optional",
  submit: "Submit",
  emailAddress: "E-mail Address",
  resetFilters: "Reset Filters",
  noExhibitionsFound: "No Exhibitions Found",
  to: "To",
  from: "From",
  type: "Type",
  noRequestsFound: "No Requests Found",
  subjectHere: "Subject here...",
  companyName: "Company name",

  // homepage
  lastCompanies: "Last Companies",
  seeAllCategories: "See all Categories",
  companiesBy: "Companies by",
  searchForCompany: "Search for Company",
  noResult: "No Result",
  fetchingCompaniesWith: "Fetching Companies with",
  projectsIdeas: "Projects Ideas",
  tendersAnd: "Tenders And",
  seeMore: "See More",
  requestTitle: "Elevate Your Savings to New Heights!",
  purchaseRequests: "Purchase Requests",
  saleOffers: "Sale Offers",
  viewMore: "View More",
  searchStepTitle1: "Streamline ordering from search to fulfillment,",
  searchStepTitle2: "All in one place",
  step1Title: "Search for companies",
  step2Title: "Find best suppliers",
  step3Title: "Ask for quotations",
  step4Title: "Make deals",
  step5Title: "Succeed in your business",

  // requests
  views: "Views",
  requestDetails: "Request Details",
  quantity: "Quantity",
  price: "Price",
  startingAt: "Starting At",
  endingAt: "Ending At",
  tags: "Tags",
  contactInfo: "Contact Info",

  // about page
  productsForSale: "Products For Sale",
  productsForSaleDesc:
    "Whole number of manufactures and companies which produce million of products",
  companiesEarnings: "Companies Earnings",
  companiesEarningsDesc:
    "Million of dollars of earnings for companies around the world",
  growingBuyers: "Growing Buyers",
  growingBuyersDesc:
    "Continuous Growing of companies spread all over the world",
  aboutLastSectionTitle: "The Role Of Mart Of World",
  aboutLastSectionDesc:
    "The idea of Mart of World project is a B2B international trading system connecting seller companies with customers to broaden the range of business and trade between different companies and customers. Therefore, the system represents a store for all companies, whether they are looking for products or services. So, the main goal is to offer a platform that links companies together where they can effortlessly agree together, and also help to get to new markets by this platform.",
  exploreMore: "Explore More",
  aboutSecondSectionTitle:
    "Mart of World consists of many firms operating in different fields of commerce, industry and marketing, which work on easing international B2B trade operations among companies.",
  aboutMainGoal: "Main Goal",
  aboutMainGoalDesc:
    "The main goal is to bring companies together from all over the world on one platform and to support them with necessary tools to develop their businesses and to get to new markets. As well, Mart of World has created an online platform that connects manufacturers, companies and entrepreneurs with customers from all over the world, which permits them doing business without geographical restrictions, hence it contains an amount of products and services, which is considered easy for companies to get best partners, best deals and best commerce.",
  aboutMartofWorld: "About Mart of World",
  internationalTrading: "International Trading ...",
  aboutMartofWorldDesc:
    "B2B commerce has facilitated and rapidly gained international trade before. Now online, people can communicate together and complete their work without getting tired. That caused for a rapid growth in international trade and has led inter-company trade as one of the most important methods in business",
  B2B: "B2B",

  // auth pages
  enterEmailToGetCode: "Enter you email to get reset password code",
  getCode: "Get Code",
  login: "Login",
  dontHavAccount: "Don't Have Account? ",
  backTo: "Back to",
  password: "Password",
  email: "Email",
  name: "Name",
  phone: "Phone",
  lostPassword: "Forgot Password?",
  logIn: "Log in",
  dontHaveAccount: "Don't have account?",
  registerNow: "Register now",
  alreadyHaveAccount: "Already have an account?",
  resetPassword: "Reset Password",
  confirmPassword: "Confirm Password",
  congrats: "Congrats...",
  youCanLogin: "Your email has been verified you can login now",
  goToLogin: "Go To Login",
  loading: "Loading...",
  verifyEmail: "Verify Email",
  register: "Register",

  // meta
  metaDescription_Homepage:
    "Home - Discover top-quality manufacturers, suppliers, exporters, importers, buyers, and wholesalers, along with products and trade leads, on our free international trade platform. Start importing and exporting today at martofworld.com",
  metaKeywords_Homepage:
    "Manufacturers, Suppliers, Exporters, Importers, Products, Trade Leads, Supplier, Manufacturer, Exporter, Importer",

  metaDescription_About: "About Mart of world",
  metaKeywords_About: "About, Mart of world",

  metaDescription_Blogs: "Blogs",
  metaKeywords_Blogs: "Blogs",

  metaDescription_Categories: "Categories",
  metaKeywords_Categories: "Categories",

  metaDescription_Companies: "Companies",
  metaKeywords_Companies: "Companies",

  metaDescription_Contact: "Contact",
  metaKeywords_Contact: "Contact",

  metaDescription_Exhibitations: "Exhibitions",
  metaKeywords_Exhibitations: "Exhibitions",

  metaDescription_Packages: "Packages",
  metaKeywords_Packages: "Packages",

  metaDescription_Requests: "Requests",
  metaKeywords_Requests: "Requests",

  metaDescription_Service: "Service",
  metaKeywords_Service: "Service",

  // new keys
  aboutUs: "About Us",
  organizer: "Organizer",
  close: "Close",

  aboutTitle2: "Mart Of World Value",
  aboutDesc2:
    "So, we try to give more value and more innovation constantly in addition to creativity and effectiveness exceeding imagination. Mart of World is a major supporter of international commerce solutions B2B among companies. As well we offer an overall group of services that allow companies to communicate together and make business easy. Furthermore, we are in all countries and have services in many languages. So, we are committed to giving the best services and offers.",
  aboutTitle3: "Based On Manhattan, New York",
  aboutDesc3: `Mart of World was founded in America, and based in Manhattan, New York, the capital of world trade, then opened a branch in Turkey, Konya as a main center in the Middle East. As well the extent of Mart of World presents in many countries involving Arab and foreign countries. Our company strives to get to different markets and standardize trading operations among companies; on the other hand, our skilled professionals can offer the best services. Hence we have a group of products we can offer to clients; also we have customized solutions for clients' needs, moreover presence of a good network of suppliers and manufacturers give us a competitive feature.`,
};
