export default {
  homepage: "Página Principal",
  blogs: "Blogs",
  blog: "Blog",
  searchByName: "buscar por nombre",
  categories: "Categorías",
  product: "Producto",
  reviews: "Reseñas",
  polices: "Políticas",
  companies: "Empresas",
  exhibitions: "Exposiciones",
  requests: "Solicitudes",
  request: "Solicitud",
  services: "Servicios",
  service: "Servicio",
  allCategories: "Todas las Categorías",
  allServices: "Todos los Servicios",
  articles: "Artículos",
  addRequest: "Add Request",
  addProjectIdea: "Add Project Idea",
  about: "Acerca de",
  contact: "Contacto",
  copyRight: "Martofworld Todos los Derechos Reservados",
  quickAccess: "Puedes Acceder Rápidamente a las Páginas que Necesitas Aquí",
  quickMenu: "Menú Rápido",
  noSubSerivce: "No hay Sub Servicio...",
  noSubCategory: "No hay Sub Categoría...",
  contactUs: "Contáctanos",

  // companies
  locationHere: "Ubicación aquí",
  chatNow: "Chatear Ahora",
  noProductsFound: "No se Encontraron Productos.",
  seeDetails: "Ver Detalles",
  segments: "Segmentos",
  sendInquiry: "Enviar Consulta",
  message: "Mensaje",
  send: "Enviar",
  keywords: "Palabras Clave",
  certificates: "Certificados",
  confirmingBanks: "Bancos Confirmantes",
  brands: "Marcas",
  address: "Dirección",
  visitCompany: "Visitar Empresa",
  selectCategory: "seleccionar categoría",
  subCategories: "Subcategorías",
  country: "País",
  countryName: "Nombre del País",
  selectCountry: "seleccionar país",
  selectSubCategory: "Seleccionar Subcategoría",
  cities: "Ciudades",
  city: "Ciudad",
  logout: "Cerrar Sesión",
  viewDashboard: "Ver Panel de Control",

  // contact
  yourName: "Tu Nombre",
  yourNameHere: "tu nombre aquí...",
  yourMessageHere: "tu mensaje aquí...",
  yourEmail: "Tu Correo Electrónico",
  subject: "Asunto",
  optional: "Opcional",
  submit: "Enviar",
  emailAddress: "Dirección de Correo Electrónico",
  resetFilters: "Restablecer Filtros",
  noExhibitionsFound: "No se Encontraron Exposiciones",
  to: "Para",
  from: "De",
  type: "Tipo",
  noRequestsFound: "No se Encontraron Solicitudes",
  subjectHere: "Asunto aquí...",
  companyName: "Nombre de la Empresa",

  // homepage
  lastCompanies: "Últimas Empresas",
  seeAllCategories: "Ver Todas las Categorías",
  companiesBy: "Empresas por",
  searchForCompany: "Buscar Empresa",
  noResult: "Sin Resultados",
  fetchingCompaniesWith: "Cargando Empresas con",
  projectsIdeas: "Ideas de Proyectos",
  tendersAnd: "Ofertas y",
  seeMore: "Ver Más",
  requestTitle: "¡Eleva tus Ahorros a Nuevas Alturas!",
  purchaseRequests: "Solicitudes de Compra",
  saleOffers: "Ofertas de Venta",
  viewMore: "Ver Más",
  searchStepTitle1: "Simplifica el pedido desde la búsqueda hasta la entrega,",
  searchStepTitle2: "Todo en un solo lugar",
  step1Title: "Buscar empresas",
  step2Title: "Encontrar los mejores proveedores",
  step3Title: "Pedir cotizaciones",
  step4Title: "Hacer acuerdos",
  step5Title: "Tener éxito en tu negocio",

  // requests
  views: "Vistas",
  requestDetails: "Detalles de la Solicitud",
  quantity: "Cantidad",
  price: "Precio",
  startingAt: "Empezando En",
  endingAt: "Terminando En",
  tags: "Etiquetas",
  contactInfo: "Información de Contacto",

  // about page
  productsForSale: "Productos en Venta",
  productsForSaleDesc:
    "Un gran número de fabricantes y empresas que producen millones de productos",
  companiesEarnings: "Ingresos de las Empresas",
  companiesEarningsDesc:
    "Millones de dólares en ingresos para empresas de todo el mundo",
  growingBuyers: "Compradores en Crecimiento",
  growingBuyersDesc: "Crecimiento continuo de empresas en todo el mundo",
  aboutLastSectionTitle: "El Rol de Mart Of World",
  aboutLastSectionDesc:
    "La idea del proyecto Mart of World es un sistema de comercio internacional B2B que conecta empresas vendedoras con clientes para ampliar el rango de negocios y comercio entre diferentes empresas y clientes. Por lo tanto, el sistema representa una tienda para todas las empresas, ya sea que busquen productos o servicios. Así, el objetivo principal es ofrecer una plataforma que conecte a las empresas, permitiéndoles acordar fácilmente entre ellas y ayudarlas a acceder a nuevos mercados.",
  exploreMore: "Explorar Más",
  aboutSecondSectionTitle:
    "Mart of World está compuesto por muchas empresas que operan en diferentes campos del comercio, la industria y el marketing, que facilitan las operaciones comerciales B2B internacionales entre empresas.",
  aboutMainGoal: "Objetivo Principal",
  aboutMainGoalDesc:
    "El objetivo principal es reunir a empresas de todo el mundo en una plataforma y proporcionarles las herramientas necesarias para desarrollar sus negocios y acceder a nuevos mercados. Además, Mart of World ha creado una plataforma en línea que conecta a fabricantes, empresas y emprendedores con clientes de todo el mundo, lo que les permite hacer negocios sin restricciones geográficas.",
  aboutMartofWorld: "Acerca de Mart of World",
  internationalTrading: "Comercio Internacional...",
  aboutMartofWorldDesc:
    "El comercio B2B ha facilitado y acelerado el crecimiento del comercio internacional. Ahora en línea, las personas pueden comunicarse entre sí y completar su trabajo sin cansarse, lo que ha provocado un rápido crecimiento en el comercio internacional, convirtiéndose en uno de los métodos más importantes en los negocios entre empresas.",
  B2B: "B2B",

  // auth pages
  enterEmailToGetCode:
    "Ingresa tu correo electrónico para recibir el código de restablecimiento de contraseña",
  getCode: "Obtener Código",
  login: "Iniciar Sesión",
  dontHavAccount: "Don't Have Account? ",
  backTo: "Volver a",
  password: "Contraseña",
  email: "Correo Electrónico",
  name: "Nombre",
  phone: "Teléfono",
  lostPassword: "¿Olvidaste tu contraseña?",
  logIn: "Iniciar Sesión",
  dontHaveAccount: "¿No tienes cuenta?",
  registerNow: "Regístrate ahora",
  alreadyHaveAccount: "¿Ya tienes una cuenta?",
  resetPassword: "Restablecer Contraseña",
  confirmPassword: "Confirmar Contraseña",
  congrats: "Felicidades...",
  youCanLogin:
    "Tu correo electrónico ha sido verificado, ahora puedes iniciar sesión",
  goToLogin: "Ir a Iniciar Sesión",
  loading: "Cargando...",
  verifyEmail: "Verificar Correo Electrónico",
  register: "Registrarse",

  // meta
  metaDescription_Homepage: "metaDescription_PáginaPrincipal",
  metaKeywords_Homepage: "metaKeywords_PáginaPrincipal",

  metaDescription_About: "metaDescription_AcercaDe",
  metaKeywords_About: "metaKeywords_AcercaDe",

  metaDescription_Blogs: "metaDescription_Blogs",
  metaKeywords_Blogs: "metaKeywords_Blogs",

  metaDescription_Categories: "metaDescription_Categorías",
  metaKeywords_Categories: "metaKeywords_Categorías",

  metaDescription_Companies: "metaDescription_Empresas",
  metaKeywords_Companies: "metaKeywords_Empresas",

  metaDescription_Contact: "metaDescription_Contacto",
  metaKeywords_Contact: "metaKeywords_Contacto",

  metaDescription_Exhibitations: "metaDescription_Exposiciones",
  metaKeywords_Exhibitations: "metaKeywords_Exposiciones",

  metaDescription_Packages: "metaDescription_Paquetes",
  metaKeywords_Packages: "metaKeywords_Paquetes",

  metaDescription_Requests: "metaDescription_Solicitudes",
  metaKeywords_Requests: "metaKeywords_Solicitudes",

  metaDescription_Service: "metaDescription_Servicio",
  metaKeywords_Service: "metaKeywords_Servicio",
};
