export default {
  homepage: "Page d'accueil",
  blogs: "Blogs",
  blog: "Blog",
  searchByName: "recherche par nom",
  categories: "Catégories",
  product: "Produit",
  reviews: "Avis",
  polices: "Politiques",
  companies: "Entreprises",
  exhibitions: "Expositions",
  requests: "Demandes",
  request: "Demande",
  services: "Services",
  service: "Service",
  allCategories: "Toutes les Catégories",
  allServices: "Tous les Services",
  articles: "Articles",
  addRequest: "Add Request",
  addProjectIdea: "Add Project Idea",
  about: "À propos",
  contact: "Contact",
  copyRight: "Martofworld Tous droits réservés",
  quickAccess:
    "Vous pouvez accéder rapidement aux pages dont vous avez besoin ici",
  quickMenu: "Menu rapide",
  noSubSerivce: "Pas de sous-service...",
  noSubCategory: "Pas de sous-catégorie...",
  contactUs: "Contactez-nous",

  // companies
  locationHere: "Emplacement ici",
  chatNow: "Discuter maintenant",
  noProductsFound: "Aucun produit trouvé.",
  seeDetails: "Voir les détails",
  segments: "Segments",
  sendInquiry: "Envoyer une demande",
  message: "Message",
  send: "Envoyer",
  keywords: "Mots-clés",
  certificates: "Certificats",
  confirmingBanks: "Banques confirmatrices",
  brands: "Marques",
  address: "Adresse",
  visitCompany: "Visiter l'entreprise",
  selectCategory: "sélectionner une catégorie",
  subCategories: "Sous-catégories",
  country: "Pays",
  countryName: "Nom du pays",
  selectCountry: "sélectionner un pays",
  selectSubCategory: "Sélectionner une sous-catégorie",
  cities: "Villes",
  city: "Ville",
  logout: "Se déconnecter",
  viewDashboard: "Voir le tableau de bord",

  // contact
  yourName: "Votre nom",
  yourNameHere: "votre nom ici...",
  yourMessageHere: "votre message ici...",
  yourEmail: "Votre email",
  subject: "Sujet",
  optional: "Optionnel",
  submit: "Soumettre",
  emailAddress: "Adresse e-mail",
  resetFilters: "Réinitialiser les filtres",
  noExhibitionsFound: "Aucune exposition trouvée",
  to: "À",
  from: "De",
  type: "Type",
  noRequestsFound: "Aucune demande trouvée",
  subjectHere: "Sujet ici...",
  companyName: "Nom de l'entreprise",

  // homepage
  lastCompanies: "Dernières entreprises",
  seeAllCategories: "Voir toutes les catégories",
  companiesBy: "Entreprises par",
  searchForCompany: "Rechercher une entreprise",
  noResult: "Aucun résultat",
  fetchingCompaniesWith: "Récupération d'entreprises avec",
  projectsIdeas: "Idées de projets",
  tendersAnd: "Offres et",
  seeMore: "Voir plus",
  requestTitle: "Élevez vos économies à de nouveaux sommets !",
  purchaseRequests: "Demandes d'achat",
  saleOffers: "Offres de vente",
  viewMore: "Voir plus",
  searchStepTitle1: "Rationaliser la commande de la recherche à la livraison,",
  searchStepTitle2: "Tout en un seul endroit",
  step1Title: "Rechercher des entreprises",
  step2Title: "Trouver les meilleurs fournisseurs",
  step3Title: "Demander des devis",
  step4Title: "Conclure des accords",
  step5Title: "Réussir dans votre entreprise",

  // requests
  views: "Vues",
  requestDetails: "Détails de la demande",
  quantity: "Quantité",
  price: "Prix",
  startingAt: "Commence à",
  endingAt: "Fini à",
  tags: "Étiquettes",
  contactInfo: "Informations de contact",

  // about page
  productsForSale: "Produits en vente",
  productsForSaleDesc:
    "Un grand nombre de fabricants et d'entreprises qui produisent des millions de produits",
  companiesEarnings: "Revenus des entreprises",
  companiesEarningsDesc:
    "Des millions de dollars de revenus pour les entreprises à travers le monde",
  growingBuyers: "Acheteurs en croissance",
  growingBuyersDesc: "Croissance continue des entreprises dans le monde entier",
  aboutLastSectionTitle: "Le rôle de Mart Of World",
  aboutLastSectionDesc:
    "Le projet Mart of World est un système de commerce international B2B qui connecte les entreprises vendeuses avec les clients afin d'élargir l'éventail des affaires et du commerce entre les différentes entreprises et clients. Ainsi, le système représente une boutique pour toutes les entreprises, qu'elles recherchent des produits ou des services. Le principal objectif est d'offrir une plateforme qui relie les entreprises entre elles, leur permettant de s'entendre facilement et de les aider à accéder à de nouveaux marchés.",
  exploreMore: "Explorer plus",
  aboutSecondSectionTitle:
    "Mart of World est composé de nombreuses entreprises opérant dans divers domaines du commerce, de l'industrie et du marketing, qui facilitent les opérations commerciales B2B internationales entre les entreprises.",
  aboutMainGoal: "Objectif principal",
  aboutMainGoalDesc:
    "Le principal objectif est de rassembler des entreprises du monde entier sur une seule plateforme et de leur fournir les outils nécessaires pour développer leurs activités et accéder à de nouveaux marchés. Mart of World a également créé une plateforme en ligne qui connecte des fabricants, des entreprises et des entrepreneurs avec des clients du monde entier, leur permettant de faire des affaires sans restrictions géographiques.",
  aboutMartofWorld: "À propos de Mart of World",
  internationalTrading: "Commerce international...",
  aboutMartofWorldDesc:
    "Le commerce B2B a facilité et accéléré la croissance rapide du commerce international. Désormais en ligne, les gens peuvent communiquer entre eux et accomplir leur travail sans se fatiguer, ce qui a entraîné une croissance rapide du commerce international, faisant du commerce inter-entreprises l'un des principaux moyens dans les affaires.",
  B2B: "B2B",

  // auth pages
  enterEmailToGetCode:
    "Entrez votre e-mail pour recevoir le code de réinitialisation du mot de passe",
  getCode: "Obtenir un code",
  login: "Connexion",
  dontHavAccount: "Don't Have Account? ",
  backTo: "Retour à",
  password: "Mot de passe",
  email: "E-mail",
  name: "Nom",
  phone: "Téléphone",
  lostPassword: "Mot de passe oublié ?",
  logIn: "Se connecter",
  dontHaveAccount: "Vous n'avez pas de compte ?",
  registerNow: "Inscrivez-vous maintenant",
  alreadyHaveAccount: "Vous avez déjà un compte ?",
  resetPassword: "Réinitialiser le mot de passe",
  confirmPassword: "Confirmer le mot de passe",
  congrats: "Félicitations...",
  youCanLogin:
    "Votre email a été vérifié, vous pouvez maintenant vous connecter",
  goToLogin: "Aller à la connexion",
  loading: "Chargement...",
  verifyEmail: "Vérifier l'e-mail",
  register: "S'inscrire",

  // meta
  metaDescription_Homepage: "metaDescription_PageAccueil",
  metaKeywords_Homepage: "metaKeywords_PageAccueil",

  metaDescription_About: "metaDescription_ÀPropos",
  metaKeywords_About: "metaKeywords_ÀPropos",

  metaDescription_Blogs: "metaDescription_Blogs",
  metaKeywords_Blogs: "metaKeywords_Blogs",

  metaDescription_Categories: "metaDescription_Catégories",
  metaKeywords_Categories: "metaKeywords_Catégories",

  metaDescription_Companies: "metaDescription_Entreprises",
  metaKeywords_Companies: "metaKeywords_Entreprises",

  metaDescription_Contact: "metaDescription_Contact",
  metaKeywords_Contact: "metaKeywords_Contact",

  metaDescription_Exhibitations: "metaDescription_Expositions",
  metaKeywords_Exhibitations: "metaKeywords_Expositions",

  metaDescription_Packages: "metaDescription_Paquets",
  metaKeywords_Packages: "metaKeywords_Paquets",

  metaDescription_Requests: "metaDescription_Demandes",
  metaKeywords_Requests: "metaKeywords_Demandes",

  metaDescription_Service: "metaDescription_Service",
  metaKeywords_Service: "metaKeywords_Service",
};
